import React, {Component} from "react"
import BaseLayout from "../components/Layout/BaseLayout";
import parse from 'html-react-parser';
import {graphql} from "gatsby";

class ErrorPage extends Component {

    constructor(props){

        super(props);

        this.state= {
            show:false
        };

    }

    componentDidMount(){
        this.setState({
            show:true
        });
    }


    render() {

        const {data} = this.props;


        return (
            <BaseLayout data={data.gravql} show={this.state.show}>
                {
                    parse(data.gravql.page.content)
                }
            </BaseLayout>
        )
    }
}

export default ErrorPage;


export const query = graphql`
{
    gravql {
        pages {
            title
            visible
            children {
                header {
                    title
                    body_classes
                }
                route
                title
            }
            route
            header {
                title
                body_classes
            }
        }
        page(route: "/error") {
            title      
            content
            route
            children {
                title
                route                
            }
            imagesCropped(crop:true, width: "200", height:"200"){
                path
                filename
                url
                width
                height
            }
            metadata {
                name
                content
            }   
        }
    }
}
`